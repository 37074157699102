













































import Base from '@/mixins/Base.vue';
import { IUser, IUserResponse } from '@/interfaces/user';

const component = Base.extend({
    data() {
        return {
            active: false,
            confirmation: '',
            user: undefined as any | IUser,
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.user.name;
        },
    },
    mounted() {
        this.active = true;
        this.getUser();
    },
    methods: {
        getUser() : void{
            this.get<IUserResponse>(`users/${this.$route.params.id}`).then(({ data }) => {
                this.user = data.data;
            });
        },
        save() : void{
            if (this.validated) {
                this.delete(`users/${this.user.id}`).then(() => {
                    this.$router.push({ name: 'users.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
